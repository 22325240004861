import { Warehouse } from '@mui/icons-material'
import Icons from '../../icons/sidebar'
import { isObservator } from '../../utils/JWTAuth'

const topMenuList = (isObservator: isObservator) => {
  const index = [
    {
      title: 'Głowne Monitorowanie',
      Icon: Icons.DashboardIcon,
      path: '/dashboard',
    },
    {
      title: 'Kontrola transakcji',
      Icon: Icons.SessionsIcon,
      path: '/dashboard/dashboard-transaction',
    },
    {
      title: 'Raporty',
      Icon: Icons.UploadIcon,
      path: '/dashboard/report-transaction',
    },
  ]

  const observerIndex = [
    ...index,
    {
      title: 'Stwórz transakcje',
      Icon: Icons.AddReduction,
      path: '/dashboard/add-transaction',
    },
    {
      title: 'Magazyn',
      Icon: Warehouse,
      path: '/dashboard/storage',
    },
  ]

  return isObservator ? observerIndex : index
}

export default topMenuList

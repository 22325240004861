import { GridView as UserGridIcon, Login as LoginIcon, PersonAdd as AddUserIcon } from '@mui/icons-material'
import AddReduction from './AddReduction'
import DashboardIcon from './Dashboard'
import SessionsIcon from './Sessions'
import UserManagementIcon from './UserManagement'
import UserProfileIcon from './UserProfile'
import NotificationsIcon from '../../icons/NotificationsIcon'
import UploadIcon from '../../icons/UploadIcon'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginIcon,
  AddReduction,
  AddUserIcon,
  SessionsIcon,
  UserGridIcon,
  DashboardIcon,
  NotificationsIcon,
  UserProfileIcon,
  UserManagementIcon,
  UploadIcon,
}

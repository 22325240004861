import { LoadingButton } from '@mui/lab'
import { Box, Button, Card, FormControlLabel, FormHelperText, Switch } from '@mui/material'
import { TextFieldWrapper } from '../../components/authentication/StyledComponents'
import FlexBox from '../../components/FlexBox'
import LightTextField from '../../components/LightTextField'
import { H1, Paragraph, Small } from '../../components/Typography'
import { useFormik } from 'formik'
import { FC, useState } from 'react'
import toast from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'
import { login } from '../../utils/JWTAuth'
import * as Yup from 'yup'

const Login: FC = () => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()

  const initialValues = {
    login: 'kz',
    password: 'iZWMzwqtCHeZv48',
    submit: null,
    remember: true,
  }

  const validationSchema = Yup.object().shape({
    login: Yup.string().min(2, 'Login powinien mieć minimum 2 znaki').required('Login jest wymagany'),
    password: Yup.string().min(6, 'Hasło powinno mieć minimum 6 znaków').required('Hasło jest wymagane'),
  })

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: any) => {
      setLoading(true)
      try {
        await login(values.login, values.password)
        setLoading(false)
        toast.success('Zalogowałeś się poprawnie do systemu')
        navigate('/dashboard')
      } catch (error: any) {
        setError('Wystąpił błąd spróbuj ponownie pózniej')
        setLoading(false)
        toast.error('Wystąpił błąd spróbuj ponownie pózniej')
        console.error(error?.message)
      }
    },
  })

  return (
    <FlexBox
      sx={{
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        height: { sm: '100%' },
      }}
    >
      <Card sx={{ padding: 4, maxWidth: 600, boxShadow: 1, width: '40%' }}>
        <FlexBox alignItems='center' flexDirection='column' justifyContent='center' mb={5}>
          <H1 fontSize={24} fontWeight={700}></H1>
        </FlexBox>
        <FlexBox justifyContent='space-between' flexWrap='wrap' my='1rem'>
          <form noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
            <FlexBox justifyContent='space-between' flexWrap='wrap'>
              <TextFieldWrapper>
                <Paragraph fontWeight={600} mb={1}>
                  Login
                </Paragraph>
                <LightTextField
                  fullWidth
                  placeholder='Wprowadź login'
                  name='login'
                  type='login'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.login || ''}
                  error={Boolean(touched.login && errors.login)}
                  helperText={touched.login && errors.login}
                />
              </TextFieldWrapper>

              <TextFieldWrapper>
                <Paragraph fontWeight={600} mb={1}>
                  Hasło
                </Paragraph>
                <LightTextField
                  fullWidth
                  placeholder='Wprowadz hasło'
                  name='password'
                  type='password'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password || ''}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </TextFieldWrapper>
            </FlexBox>

            <FlexBox mt={2} alignItems='center' justifyContent='space-between'>
              <FormControlLabel
                control={<Switch name='remember' checked={values.remember} onChange={handleChange} />}
                label='Zapamiętaj mnie'
                sx={{ '& .MuiTypography-root': { fontWeight: 600 } }}
              />
              <Link to='/forget-password'>
                <Small color='secondary.red'>Zapomniałeś hasła?</Small>
              </Link>
            </FlexBox>

            {error && (
              <FormHelperText
                error
                sx={{
                  mt: 2,
                  fontSize: 13,
                  fontWeight: 500,
                  textAlign: 'center',
                }}
              >
                {error}
              </FormHelperText>
            )}

            <Box sx={{ mt: 4 }}>
              {loading ? (
                <LoadingButton loading fullWidth variant='contained'>
                  Zaloguj się
                </LoadingButton>
              ) : (
                <Button fullWidth type='submit' variant='contained'>
                  Zaloguj się
                </Button>
              )}
            </Box>
          </form>
        </FlexBox>
      </Card>
    </FlexBox>
  )
}

export default Login

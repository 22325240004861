import AuthGuard from '../src/components/authentication/AuthGuard'
import GuestGuard from '../src/components/authentication/GuestGuard'
import DashboardLayout from '../src/components/Layouts/DashboardLayout'
import LoadingScreen from '../src/components/LoadingScreen'
import { FC, lazy, LazyExoticComponent, Suspense } from 'react'
import { Navigate } from 'react-router-dom'

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )

// authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')))
const ForgetPassword = Loadable(lazy(() => import('./pages/authentication/ForgetPassword')))

// Dashboard pages
const DashboardSaaS = Loadable(lazy(() => import('./pages/transactionList/TransactionList')))
const Dashboard = Loadable(lazy(() => import('./pages/dashboards/Dashboards')))

// user management
const EditLoadingHours = Loadable(lazy(() => import('./components/TransactionForm/EditTransactionHours')))
const TransactionForm = Loadable(lazy(() => import('./pages/transactionForm/TransactionForm')))

// report
const Report = Loadable(lazy(() => import('./pages/report/Report')))

// Magazine
const Magazine = Loadable(lazy(() => import('./pages/magazine/Magazine')))

// error
const Error = Loadable(lazy(() => import('./pages/404')))

// routes
const routes = [
  {
    path: '/',
    element: <Navigate to='dashboard' />,
  },
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: 'forget-password',
    element: (
      <GuestGuard>
        <ForgetPassword />
      </GuestGuard>
    ),
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
      {
        path: 'edit-loading',
        element: <EditLoadingHours />,
      },
      {
        path: 'add-transaction',
        element: <TransactionForm />,
      },
      {
        path: 'report-transaction',
        element: <Report />,
      },
      {
        path: 'dashboard-transaction',
        element: <DashboardSaaS />,
      },
      {
        path: 'storage',
        element: <Magazine />,
      },
    ],
  },
  {
    path: '*',
    element: <Error />,
  },
]

export default routes

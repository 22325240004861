import TitleContextProvider from '../src/contexts/TitleContext'
import 'nprogress/nprogress.css'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import 'react-image-lightbox/style.css'
import { BrowserRouter } from 'react-router-dom'
import 'simplebar/dist/simplebar.min.css'
import App from './App'

ReactDOM.render(
  <StrictMode>
    <TitleContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </TitleContextProvider>
  </StrictMode>,
  document.getElementById('root')
)

import axios, { AxiosInstance } from 'axios'
import { b2Services } from './services/b2'

interface AuthResponse {
  access: string
  refresh: string
}

interface User {
  id: number
  username: string
}

export interface isObservator {
  isObservator: boolean
}

interface AuthState {
  user: User
  accessToken: string
  refreshToken: string
}

const axiosInstance: AxiosInstance = axios.create({
  baseURL: 'http://b2-1657913109.eu-central-1.elb.amazonaws.com',
})

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    const { response } = error

    if (response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      try {
        let authState
        const authStateString = localStorage.getItem('accessToken')
        if (authStateString !== null) {
          authState = JSON.parse(authStateString)
        }

        const { refreshToken } = authState
        const { data } = await axios.post<AuthResponse>(`${b2Services}/auth/jwt/refresh/`, {
          refresh: refreshToken,
        })

        if (!data.access) {
          logout()
          window.location.href = '/'
        } else {
          authState.accessToken = data.access
          localStorage.setItem('accessToken', JSON.stringify(authState))
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${data.access}`
          if (data?.access) {
            error.config.headers.Authorization = `JWT ${data.access}`
            return axiosInstance(originalRequest)
          }
        }
      } catch (e: any) {
        console.error('Failed to refresh access token:', e.message)
        logout()
        window.location.href = '/'
        return Promise.reject(error)
      }
    }

    return Promise.reject(error)
  }
)

async function login(login: string, password: string): Promise<void> {
  const body = {
    username: login,
    password,
  }
  const { data } = await axios.post<AuthResponse>(`${b2Services}/auth/jwt/create/`, body)
  if (data?.access) {
    await authVerify(data.access)
  }
  const authState: AuthState = {
    user: {
      id: 1,
      username: body.username,
    },
    accessToken: data.access,
    refreshToken: data.refresh,
  }
  localStorage.setItem('accessToken', JSON.stringify(authState))
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${data.access}`
}

async function authVerify(access: string): Promise<void> {
  const response: any = await axiosInstance.get(`${b2Services}/auth/users/me/`, {
    headers: {
      Authorization: 'JWT ' + access,
    },
  })
  if (response?.data) {
    const authState = { isOperator: response.data.groups[0] === 2 }
    localStorage.setItem('isOperator', JSON.stringify(authState))
  }
}

function logout(): void {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('isOperator')
  axiosInstance.defaults.headers.common.Authorization = ''
}

function getCurrentUser(): User {
  let authState
  const authStateString = localStorage.getItem('accessToken')
  if (authStateString !== null) {
    authState = JSON.parse(authStateString)
  }
  return authState ? authState : null
}

function getAuthorization(): User {
  let authState
  const authStateString = localStorage.getItem('accessToken')
  if (authStateString !== null) {
    authState = JSON.parse(authStateString)
  }
  return authState ? authState.accessToken : null
}

function getIsOperator(): isObservator {
  let operatorState
  const operatorStateString = localStorage.getItem('isOperator')
  if (operatorStateString !== null) {
    operatorState = JSON.parse(operatorStateString)
  }

  return operatorState ? operatorState.isOperator : false
}

export { axiosInstance, login, logout, getCurrentUser, getAuthorization, getIsOperator }
